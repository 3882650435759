import apiClient from '@/services/axios'

const Dashboard = {
  getDashboardChannels: function(params) {
    return apiClient({
      method: 'get',
      url: 'bi/dashboard2/dash/channels',
      params,
    })
  },
}

export default Dashboard
